
import { defineComponent, ref, markRaw, onMounted } from 'vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInAuditEventLog from '@/composable/useGridInAuditEventLog'

export default defineComponent({
  props: {
    defaultColumns: {
      type: Object
    }
  },
  components: {
    GridWrapper,
    Grid,
    GridPageInfo
  },
  setup(props, content) {
    // Grid 相關
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler
    } = useGridInAuditEventLog(props.defaultColumns)

    // cell default template 取得此欄位 fieldName 對應的值
    const getNestedValue = (fieldName, dataItem) => {
      const path = fieldName.split('.')
      let data = dataItem
      path.forEach(p => {
        data = data ? data[p] : undefined
      })

      return data
    }

    onMounted(() => {
      dataItems.value = createRandomData(255) as never[]
    })

    return {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler,
      getNestedValue
    }
  }
})
