
import { defineComponent, ref } from 'vue'
import KendoTreeView from '@/components/Kendo/KendoTreeView.vue'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlDropdown from '@/components/Control/ControlDropdown.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import FilterUnitCheckbox from '@/components/Filter/FilterUnitCheckbox.vue'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterCheckbox from '@/composable/useFilterCheckbox'
import useFilterUnitList from '@/composable/useFilterUnitList'
import KendoGrid from '@/components/Kendo/KendoGrid.vue'
import { DropDownList } from '@progress/kendo-vue-dropdowns'
import { DateTimePicker } from '@progress/kendo-vue-dateinputs'

import {
  eventCheckboxOptions,
  eventInitialCheckedIds,
  keyWithOptions,
  initialFilterUnitList
} from '@/mock/data/auditlog'

export default defineComponent({
  components: {
    KendoTreeView,
    ControlWrapper,
    ControlDropdown,
    FilterWrapper,
    FilterTextInput,
    FilterUnitCheckbox,
    FilterUnitDeletable,
    FilterUnitAdd,
    KendoGrid,
    DropDownList,
    DateTimePicker
  },
  setup() {
    const isTreeViewOpen = ref(true)

    const treeViewClose = () => {
      isTreeViewOpen.value = false
    }

    // Header 欄位
    const defaultColumns = ref([
      {
        field: 'Id',
        title: 'No',
        width: '50px'
      },
      {
        field: 'EventType',
        title: '事件類型',
        width: '190px'
      },
      {
        field: 'EventStart',
        title: '事件時間',
        width: '180px'
      },
      {
        field: 'Site',
        title: '區域名稱',
        width: '155px'
      },
      {
        field: 'Account',
        title: '帳號',
        width: '100px'
      },
      {
        field: 'Detail',
        title: '事件詳情',
        width: 'auto'
      }
    ])

    // 主機狀態 checkbox 篩選
    const {
      allChecked: eventAllChecked,
      checkedIds: eventCheckIds,
      onCheck: eventOnCheck,
      checkAll: eventCheckAll
    } = useFilterCheckbox(eventCheckboxOptions, eventInitialCheckedIds)

    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    // DateTimePicker defaultValue
    const defaultValue = ref(new Date())
    const defaultItem = {
      text: '自訂'
    }
    const dates = ref([
      { text: '今天', id: 1 },
      { text: '當週', id: 2 },
      { text: '當月', id: 3 },
      { text: '當季', id: 4 },
      { text: '今年', id: 5 },
      { text: '昨天', id: 6 },
      { text: '上週', id: 7 },
      { text: '上月', id: 8 },
      { text: '上季', id: 9 }
    ])

    return {
      isTreeViewOpen,
      treeViewClose,
      defaultColumns,
      defaultValue,
      defaultItem,
      dates,
      // 篩選相關：主機狀態 多選選項
      eventCheckboxOptions,
      eventCheckIds,
      eventAllChecked,
      eventOnCheck,
      eventCheckAll,
      // 篩選相關：自訂篩選
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    }
  }
})
