
import { defineComponent, ref, markRaw } from 'vue'
import { TreeView } from '@progress/kendo-treeview-vue-wrapper'

export default defineComponent({
  components: {
    TreeView
  },
  emits: ['close'],
  setup(props, content) {
    const emitClose = () => {
      content.emit('close')
    }

    const localDataSource = markRaw(
      // eslint-disable-next-line
        new kendo.data.HierarchicalDataSource({
        data: [
          {
            id: 0,
            name: '全部',
            isParent: true,
            icons: [],
            count: 2392,
            expanded: true,
            childrens: [
              {
                id: 1,
                name: 'Kevin Office',
                isParent: false,
                icons: ['tree-i-site'],
                count: 1340
              },
              {
                id: 2,
                name: 'Taipei Office',
                isParent: true,
                icons: ['tree-i-site'],
                count: 1020,
                expanded: true,
                childrens: [
                  {
                    id: 3,
                    name: 'R&D Department',
                    isParent: true,
                    icons: ['tree-i-network'],
                    count: 1020,
                    childrens: [
                      {
                        id: 4,
                        name: '192.168.67.X',
                        icons: ['tree-i-ip'],
                        count: 43
                      }
                    ]
                  },
                  {
                    id: 5,
                    name: 'R&D Department',
                    isParent: true,
                    icons: ['tree-i-network'],
                    count: 1020,
                    expanded: true,
                    childrens: [
                      {
                        id: 6,
                        name: '192.168.67.X',
                        icons: ['tree-i-ip'],
                        count: 40
                      }
                    ]
                  },
                  {
                    id: 7,
                    name: 'Test',
                    isParent: false,
                    icons: ['tree-i-network'],
                    count: 32
                  }
                ]
              }
            ]
          }
        ],
        schema: {
          model: {
            id: 'id',
            hasChildren: 'isParent',
            children: 'childrens',
            schema: {
              model: {
                id: 'id',
                hasChildren: 'isParent'
              }
            }
          }
        }
      })
    )

    const template =
      '# for (i = 0; i < item.icons.length; i++ ) { #' +
      '<span class="tree-icon ' +
      '#: item.icons[i] #' +
      '"></span>' +
      '# } #' +
      '#: item.name #' +
      '<span class="child-count">' +
      '#: Number(item.count).toLocaleString() #' + // 1000 -> "1,000"
      '</span>'

    // treeview template refs
    const treeViewRef = ref(null)
    // 取得 checked node 的 id
    const getCheckedNodeIds = (nodes, checkedNodes) => {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].checked) {
          checkedNodes.push(nodes[i].id)
        }

        if (nodes[i].hasChildren) {
          getCheckedNodeIds(nodes[i].children.view(), checkedNodes)
        }
      }
    }
    // treeview onCheck 處理
    // CHECK: (https://www.telerik.com/kendo-vue-ui/components/treeview/checkboxes/)
    const onTreeViewCheck = () => {
      //@ts-ignore
      const treeView = treeViewRef.value.kendoWidget()
      // 被選取的 nodes
      const checkedNodes = []

      getCheckedNodeIds(treeView.dataSource.view(), checkedNodes)

      if (checkedNodes.length > 0) {
        console.log(
          'IDs of checked nodes in treeview: ' + checkedNodes.join(',')
        )
        // FIXME: 已取得 checkedNodes，可以用來作後續的查找
      } else {
        console.log('No nodes checked in treeview.')
      }
    }

    return {
      emitClose,
      localDataSource,
      template,
      treeViewRef,
      onTreeViewCheck
    }
  }
})
