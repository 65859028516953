<template>
  <GridWrapper>
    <GridPageInfo :skip="skip" :take="take" :total="total" />
    <Grid
      id="kendo-grid"
      :style="{ height: 'calc(100vh - 240px)' }"
      :columns="columns"
      :data-items="result"
      :pageable="pageable"
      :skip="skip"
      :take="take"
      :total="total"
      :cell-render="'defaultTemplate'"
      @pagechange="pageChangeHandler"
      :scrollable="'true'"
      :selected-field="'selected'"
      @rowclick="onRowClick"
      :reorderable="true"
      @columnreorder="columnReorder"
      :sortable="true"
      :sort="sort"
      @sortchange="sortChangeHandler"
    >
      <!-- 預設 cell render template，帶有 ['data-id'] -->
      <template v-slot:defaultTemplate="{ props }">
        <td :class="props.className" :data-id="props.dataItem.Id">
          {{ getNestedValue(props.field, props.dataItem) }}
        </td>
      </template>
    </Grid>
  </GridWrapper>
</template>

<script lang="ts">
import { defineComponent, ref, markRaw, onMounted } from 'vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInAuditEventLog from '@/composable/useGridInAuditEventLog'

export default defineComponent({
  props: {
    defaultColumns: {
      type: Object
    }
  },
  components: {
    GridWrapper,
    Grid,
    GridPageInfo
  },
  setup(props, content) {
    // Grid 相關
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler
    } = useGridInAuditEventLog(props.defaultColumns)

    // cell default template 取得此欄位 fieldName 對應的值
    const getNestedValue = (fieldName, dataItem) => {
      const path = fieldName.split('.')
      let data = dataItem
      path.forEach(p => {
        data = data ? data[p] : undefined
      })

      return data
    }

    onMounted(() => {
      dataItems.value = createRandomData(255) as never[]
    })

    return {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler,
      getNestedValue
    }
  }
})
</script>

<style scoped></style>
