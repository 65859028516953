// 管理日誌 列表

// 所有事件類型 checkbox 選項
const eventCheckboxOptions = [
  { id: 'SwitchTimeout', name: '未回應', divider: false },
  { id: 'NewIPv6Range', name: '新增 - IPv6 管理範圍', divider: false }
]
// 起始的事件類型態勾選 checkbox 選項
const eventInitialCheckedIds = ['SwitchTimeout', 'NewIPv6Range']

// 可用的篩選條件：FilterUnitAdd.vue 元件
const keyWithOptions = [
  { key: '區域名稱', options: ['Taipei Office', 'Kaohsiung Office'] }
]
// 起始的篩選組合：FilterUnitDeletable.vue 元件
const initialFilterUnitList = [
  {
    id: 0,
    key: '區域名稱',
    operator: '==',
    value: 'Taipei Office',
    options: ['Taipei Office', 'Kaohsiung Office'] // 值有選項，會渲染下拉選單
  }
]

export {
  eventCheckboxOptions,
  eventInitialCheckedIds,
  keyWithOptions,
  initialFilterUnitList
}
