import { ref, computed } from 'vue'
import { orderBy } from '@progress/kendo-data-query'
// Cells

// 主機列表中使用 Kendo Grid
const useGridInAuditEventLog = defaultColumns => {
  const columns = ref(defaultColumns)

  // grid 資料
  const dataItems = ref([])
  // 分頁用
  const pageable = {
    buttonCount: 1,
    pageSizes: [16, 32, 128, 255],
    type: 'input',
    info: false
  }
  const skip = ref(0)
  const take = ref(16)
  // 排序用
  const sort = ref([])
  // 選擇
  const selectedID = ref(0)
  const onRowClick = e => {
    console.log('onRowClick Id: ' + e.dataItem.Id)
    selectedID.value = e.dataItem.Id
  }

  // 手動分頁
  const result = computed(() => {
    const dataItemsWithPage = dataItems.value.slice(
      skip.value,
      take.value + skip.value
    )
    const dataItemsWithPageAndSort = orderBy(dataItemsWithPage, sort.value)
    return dataItemsWithPageAndSort.map(item => ({
      ...item,
      selected: item.Id === selectedID.value
    }))
  })
  // 總資料數
  const total = computed(() => {
    return dataItems.value ? dataItems.value.length : 0
  })

  // 生成假資料
  const createRandomData = count => {
    return Array(count)
      .fill({})
      .map((_, idx) => ({
        Id: idx + 1, // Unique Id for select
        EventType: '未授權 MAC 封鎖',
        EventStart: '2020/02/02 14:35:07',
        Site: 'San Jose Office',
        Account: 'user',
        Detail: '備註'
      }))
  }

  const pageChangeHandler = e => {
    skip.value = e.page.skip
    take.value = e.page.take
  }

  // columns 重新排序 靠 orderIndex 做紀錄每個欄位的順序
  const columnReorder = options => {
    columns.value = options.columns
  }

  // sort 處理
  const sortChangeHandler = e => {
    console.log('sort')
    sort.value = e.sort
  }

  return {
    columns,
    dataItems,
    pageable,
    skip,
    take,
    result,
    total,
    sort,
    createRandomData,
    pageChangeHandler,
    onRowClick,
    columnReorder,
    sortChangeHandler
  }
}

export default useGridInAuditEventLog
