<template>
  <div
    class="page-audit main-list-view"
    :class="{ 'is-treeview-open': isTreeViewOpen }"
  >
    <!-- 左側：樹狀圖 -->
    <KendoTreeView @close="treeViewClose" />

    <div class="main-list-container">
      <div class="title-wrapper">
        <h2 class="title">管理日誌</h2>
        <div
          class="toggle-treeview-btn"
          @click="isTreeViewOpen = !isTreeViewOpen"
        >
          <SvgIcon icon="tree" />
        </div>
      </div>
      <!-- 上：控制列容器 -->
      <ControlWrapper>
        <ControlDropdown
          iconName="download"
          text="匯出"
          id="export"
          :menuData="[{ name: '匯出 Excel' }, { name: '匯出 CSV' }]"
        />
      </ControlWrapper>

      <!-- 中：篩選列容器 -->
      <FilterWrapper>
        <DropDownList
          :default-item="defaultItem"
          :data-items="dates"
          :text-field="'text'"
          class="time-dropdown"
        />
        <DateTimePicker
          :default-value="defaultValue"
          :format="'yyyy/MM/dd hh:mm:ss a'"
          :width="'280px'"
          :style="{ marginLeft: '10px', marginRight: '10px' }"
        >
        </DateTimePicker>
        <DateTimePicker
          :default-value="defaultValue"
          :format="'yyyy/MM/dd hh:mm:ss a'"
          :width="'280px'"
          :style="{ marginRight: '10px' }"
        >
        </DateTimePicker>
        <FilterUnitCheckbox
          :allChecked="eventAllChecked"
          :checkedIds="eventCheckIds"
          :keyName="'事件類型'"
          class=""
        >
          <div class="text-input-in-filter" style="marginTop: -25px;">
            <div class="mx-3 text-right mb-2">
              已選取 <span class="text-primary">10</span> 個篩選
            </div>
            <FilterTextInput class="ml-2 mb-3" style="width: 330px;" />
          </div>
          <label class="base-checkbox filter-checkbox">
            <input
              type="checkbox"
              v-model="eventAllChecked"
              @change="eventCheckAll"
            />
            <div class="custom-checkbox"></div>
            <span>全部</span>
          </label>
          <div class="divider"></div>
          <template v-for="option in eventCheckboxOptions" :key="option.id">
            <label class="base-checkbox filter-checkbox">
              <input
                type="checkbox"
                :value="option.id"
                v-model="eventCheckIds"
                @change="eventOnCheck"
              />
              <div class="custom-checkbox"></div>
              <span>{{ option.name }}</span>
            </label>
            <div class="divider" v-if="option.divider"></div>
          </template>
        </FilterUnitCheckbox>

        <FilterUnitDeletable
          v-for="filterUnit in filterUnitList"
          :filterUnit="filterUnit"
          :key="filterUnit.key"
          @updateFilterUnit="updateFilterUnit"
          @deleteFilterUnit="deleteFilterUnit"
        />

        <FilterUnitAdd
          :keyWithOptions="keyWithOptions"
          @addFilterUnit="addFilterUnit"
        />
        <div class="spacer"></div>
      </FilterWrapper>

      <KendoGrid :defaultColumns="defaultColumns" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import KendoTreeView from '@/components/Kendo/KendoTreeView.vue'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlDropdown from '@/components/Control/ControlDropdown.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import FilterUnitCheckbox from '@/components/Filter/FilterUnitCheckbox.vue'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterCheckbox from '@/composable/useFilterCheckbox'
import useFilterUnitList from '@/composable/useFilterUnitList'
import KendoGrid from '@/components/Kendo/KendoGrid.vue'
import { DropDownList } from '@progress/kendo-vue-dropdowns'
import { DateTimePicker } from '@progress/kendo-vue-dateinputs'

import {
  eventCheckboxOptions,
  eventInitialCheckedIds,
  keyWithOptions,
  initialFilterUnitList
} from '@/mock/data/auditlog'

export default defineComponent({
  components: {
    KendoTreeView,
    ControlWrapper,
    ControlDropdown,
    FilterWrapper,
    FilterTextInput,
    FilterUnitCheckbox,
    FilterUnitDeletable,
    FilterUnitAdd,
    KendoGrid,
    DropDownList,
    DateTimePicker
  },
  setup() {
    const isTreeViewOpen = ref(true)

    const treeViewClose = () => {
      isTreeViewOpen.value = false
    }

    // Header 欄位
    const defaultColumns = ref([
      {
        field: 'Id',
        title: 'No',
        width: '50px'
      },
      {
        field: 'EventType',
        title: '事件類型',
        width: '190px'
      },
      {
        field: 'EventStart',
        title: '事件時間',
        width: '180px'
      },
      {
        field: 'Site',
        title: '區域名稱',
        width: '155px'
      },
      {
        field: 'Account',
        title: '帳號',
        width: '100px'
      },
      {
        field: 'Detail',
        title: '事件詳情',
        width: 'auto'
      }
    ])

    // 主機狀態 checkbox 篩選
    const {
      allChecked: eventAllChecked,
      checkedIds: eventCheckIds,
      onCheck: eventOnCheck,
      checkAll: eventCheckAll
    } = useFilterCheckbox(eventCheckboxOptions, eventInitialCheckedIds)

    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    // DateTimePicker defaultValue
    const defaultValue = ref(new Date())
    const defaultItem = {
      text: '自訂'
    }
    const dates = ref([
      { text: '今天', id: 1 },
      { text: '當週', id: 2 },
      { text: '當月', id: 3 },
      { text: '當季', id: 4 },
      { text: '今年', id: 5 },
      { text: '昨天', id: 6 },
      { text: '上週', id: 7 },
      { text: '上月', id: 8 },
      { text: '上季', id: 9 }
    ])

    return {
      isTreeViewOpen,
      treeViewClose,
      defaultColumns,
      defaultValue,
      defaultItem,
      dates,
      // 篩選相關：主機狀態 多選選項
      eventCheckboxOptions,
      eventCheckIds,
      eventAllChecked,
      eventOnCheck,
      eventCheckAll,
      // 篩選相關：自訂篩選
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    }
  }
})
</script>

<style scoped></style>
